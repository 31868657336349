export const PRIVACY_POLICY = "/privacy-policy";

export const ADMIN_PREFIX = "/admin";
export const PARTENER_PREFIX = "/partener";

export const ADMIN_LAND_PAGE = ADMIN_PREFIX;
export const HOME = ADMIN_PREFIX + "/home";
export const SIGN_IN = ADMIN_PREFIX + "/login";
export const REGISTER = ADMIN_PREFIX + "/register";
/* vendor */
export const PARTNERHOME = ADMIN_PREFIX + "/partnerHome";
export const PARTNERPARENTS = ADMIN_PREFIX + "/partnerParents";
export const STUDENTACTIVITY = ADMIN_PREFIX + "/studentActivity/:studentId";
export const VENDORDASHBOARD = ADMIN_PREFIX + "/dashboard";
export const PARTNERKIDS = ADMIN_PREFIX + "/kids";
export const DETAILED_VIEWS_REPORT =
  ADMIN_PREFIX + "/detailedViewsReport/:type";
export const DETAILED_ACTIVITIES_REPORT =
  ADMIN_PREFIX + "/detailedActivitiesReport/:type";
export const ACTIVITY_DETAIL_VIEW = ADMIN_PREFIX + "/activityDetails";
export const VIEWS_DETAIL_VIEW = ADMIN_PREFIX + "/viewsDetails";

/* UserManagement */
export const USER_MANAGEMENT = ADMIN_PREFIX + "/UserManagement";

/* Customer */
export const CUSTOMERS = ADMIN_PREFIX + "/Customer";
export const VIEW_CUSTOMER = ADMIN_PREFIX + "/ViewCustomer";
export const CUSTOMER = VIEW_CUSTOMER + "/:customerUid";

/* adding Evaluation Routers */
export const EVALUATION = ADMIN_PREFIX + "/Evaluation";

/* adding Song Routers */
export const ALL_SONGS = ADMIN_PREFIX + "/getAllSongs";
export const ADD_SONG = ADMIN_PREFIX + "/addSong";
export const SONG_TO_EDIT = ADMIN_PREFIX + "/editSong";
export const EDIT_SONG =
  SONG_TO_EDIT + "/:songParentName/:songParentId/:songId";
export const SONG_TO_GET = ADMIN_PREFIX + "/songDetails";
export const SONG_DETAIL =
  SONG_TO_GET + "/:songParentName/:songParentId/:songId";
export const PUBLISH_SONG = ADMIN_PREFIX + "/publishSong";
export const SONG_EVALUATION = ADMIN_PREFIX + "/SongEvaluation";
export const SONG_ACTIVITY_TO_EDIT = ADMIN_PREFIX + "/EditActivitySong";
export const EDIT_SONG_ACTIVITY =
  SONG_ACTIVITY_TO_EDIT + "/:activityId/:songParentName/:songParentId/:songId";
export const SONG_EVALUATION_TO_EDIT = ADMIN_PREFIX + "/EditEvaluationSong";
export const EDIT_SONG_EVALUATION =
  SONG_EVALUATION_TO_EDIT +
  "/:activityId/:songParentName/:songParentId/:songId";
export const ADD_SONG_ACTIVITY = ADMIN_PREFIX + "/addSongActivity";

/* adding Song Parent Routes*/
export const ALL_SONG_PARENTS = ADMIN_PREFIX + "/GetAllSongParents";
export const ADD_SONG_PARENT = ADMIN_PREFIX + "/AddSongParent";
export const VIEW_SONG_PARENT = ADMIN_PREFIX + "/ViewSongParent";
export const EDIT_SONG_PARENT = ADMIN_PREFIX + "/EditSongParent";
/* adding Book Parent Routes*/
export const ALL_BOOK_PARENTS = ADMIN_PREFIX + "/GetAllBookParents";
export const ADD_BOOK_PARENT = ADMIN_PREFIX + "/AddBookParent";
export const VIEW_BOOK_PARENT = ADMIN_PREFIX + "/ViewBookParent";
export const EDIT_BOOK_PARENT = ADMIN_PREFIX + "/EditBookParent";
/* adding Game Parent Routes*/
export const ALL_GAME_PARENTS = ADMIN_PREFIX + "/GetAllGameParents";
export const ADD_GAME_PARENT = ADMIN_PREFIX + "/AddGameParent";
export const VIEW_GAME_PARENT = ADMIN_PREFIX + "/ViewGameParent";
export const EDIT_GAME_PARENT = ADMIN_PREFIX + "/EditGameParent";

/* adding Game Routers */
export const ALL_GAMES = ADMIN_PREFIX + "/getAllGames";
export const PUZZLE_DETAIL = ADMIN_PREFIX + "/puzzleDetails";
export const DRAG_DROP_DETAIL = ADMIN_PREFIX + "/dragDropDetails";
export const PAINTING_DETAIL = ADMIN_PREFIX + "/paintingDetails";
export const ADD_DRAG_DROP = ADMIN_PREFIX + "/addDragDrop";
export const DRAG_DROP_TO_EDIT = ADMIN_PREFIX + "/editDragDrop";
export const EDIT_DRAG_DROP = DRAG_DROP_TO_EDIT + "/:id";
export const JUMPING_DETAIL = ADMIN_PREFIX + "/jumpingDetails";
export const JUMPING_TO_EDIT = ADMIN_PREFIX + "/editJumping";
export const EDIT_JUMPING = JUMPING_TO_EDIT + "/:activityId";

/*Game Data*/
export const GAME_DATA_DETAIL = ADMIN_PREFIX + "/GetAllGameData";
export const ADD_GAME_DATA = ADMIN_PREFIX + "/AddGameData";
export const GAME_DATA_TO_EDIT = ADMIN_PREFIX + "/EditGameData";
export const EDIT_GAME_DATA = GAME_DATA_TO_EDIT + "/:gameParentId/:gameId";

/* Discover us series */
export const ALL_DISCOVER_SERIES = ADMIN_PREFIX + "/getAllDiscoverUsSeries";
export const VIEW_DISCOVER_SERIES = ADMIN_PREFIX + "/viewDiscoverUsSeries";
export const ADD_DISCOVER_SERIES = ADMIN_PREFIX + "/AddDiscoverUsSeries";
export const UPDATE_DISCOVER_SERIES = ADMIN_PREFIX + "/UpdateDiscoverUsSeries";

/* adding Series Routers */
export const ALL_SERIES = ADMIN_PREFIX + "/GetAllSeries";
export const ADD_SERIES = ADMIN_PREFIX + "/AddSeries";
export const UPDATE_SERIES = ADMIN_PREFIX + "/UpdateSeries";
export const VIEW_SERIES = ADMIN_PREFIX + "/ViewSeries";
export const GET_SERIES_LIST = ADMIN_PREFIX + "/GetAllSeriesList";

/* adding Episode Routers */
export const ADD_EPISODE = ADMIN_PREFIX + "/AddEpisode";
export const PUBLISH_EPISODE = ADMIN_PREFIX + "/PublishEpisode";
export const GET_EPISODE_TO_VIEW = ADMIN_PREFIX + "/ViewEpisode";
export const VIEW_EPISODE =
  GET_EPISODE_TO_VIEW + "/:seriesName/:seriesId/:episodeId";
export const ADD_EPISODE_ACTIVITY = ADMIN_PREFIX + "/AddActivityEpisode";
export const GET_EPISODE_TO_EDIT = ADMIN_PREFIX + "/EditEpisode";
export const EDIT_EPISODE = GET_EPISODE_TO_EDIT + "/:seriesId/:episodeId";
export const EPISODE_EVALUATION = ADMIN_PREFIX + "/EpisodeEvaluation";
export const GET_EPISODE_ACTIVITY_TO_EDIT =
  ADMIN_PREFIX + "/EditActivityEpisode";
export const EDIT_EPISODE_ACTIVITY =
  GET_EPISODE_ACTIVITY_TO_EDIT +
  "/:activityId/:seriesName/:seriesId/:episodeId";
export const GET_EPISODE_EVALUATION_TO_EDIT =
  ADMIN_PREFIX + "/EditEvaluationEpisode";
export const EDIT_EPISODE_EVALUATION =
  GET_EPISODE_EVALUATION_TO_EDIT +
  "/:activityId/:seriesName/:seriesId/:episodeId";

/* adding story Routers */
export const ADD_STORY = ADMIN_PREFIX + "/addStory";
export const ALL_STORIES = ADMIN_PREFIX + "/getAllStories";
export const ADD_STORY_ACTIVITY = ADMIN_PREFIX + "/addActivityStory";
export const PUBLISH_STORY = ADMIN_PREFIX + "/publishStory";
export const STORY_TO_GET = ADMIN_PREFIX + "/storyDetails";
export const STORY_DETAIL =
  STORY_TO_GET + "/:storyParentName/:storyParentId/:storyId";
export const STORY_EVALUATION = ADMIN_PREFIX + "/StoryEvaluation";
export const STORY_TO_EDIT = ADMIN_PREFIX + "/editStory";
export const EDIT_STORY =
  STORY_TO_EDIT + "/:storyParentName/:storyParentId/:storyId";

export const GET_STORY_ACTIVITY_TO_EDIT = ADMIN_PREFIX + "/EditActivityStory";
export const EDIT_STORY_ACTIVITY =
  GET_STORY_ACTIVITY_TO_EDIT +
  "/:activityId/:storyParentName/:storyParentId/:storyId";
export const GET_STORY_EVALUATION_TO_EDIT =
  ADMIN_PREFIX + "/EditEvaluationStory";
export const EDIT_STORY_EVALUATION =
  GET_STORY_EVALUATION_TO_EDIT +
  "/:activityId/:storyParentName/:storyParentId/:storyId";

/* adding Story Parent */
export const ALL_STORIES_PARENTS = ADMIN_PREFIX + "/GetAllStoryParents";
export const ADD_STORY_PARENT = ADMIN_PREFIX + "/AddStoryParent";
export const VIEW_STORY_PARENT = ADMIN_PREFIX + "/ViewStoryParent";
export const EDIT_STORY_PARENT = ADMIN_PREFIX + "/EditStoryParent";

/*Radio Jeel*/
export const ALL_RADIO = ADMIN_PREFIX + "/GetAllRadio";
export const ADD_RADIO = ADMIN_PREFIX + "/AddRadio";
export const VIEW_RADIO = ADMIN_PREFIX + "/ViewRadio";
export const EDIT_RADIO = ADMIN_PREFIX + "/EditRadio";

export const ALL_RADIO_PAGE_NAME = "GetAllRadio";

/* adding Radio Channel Routers */
export const ADD_RADIO_CHANNEL = ADMIN_PREFIX + "/AddRadioChannel";
export const RADIO_CHANNEL_TO_EDIT = ADMIN_PREFIX + "/EditRadioChannel";
export const EDIT_RADIO_CHANNEL =
  RADIO_CHANNEL_TO_EDIT + "/:radioName/:radioId/:channelId";
export const RADIO_CAHNNEL_TO_GET = ADMIN_PREFIX + "/RadioChannelDetails";
export const VIEW_RADIO_CHANNEL =
  RADIO_CAHNNEL_TO_GET + "/:radioName/:radioId/:channelId";
export const PUBLISH_RADIO_CHANNEL = ADMIN_PREFIX + "/PublishRadioChannel";
export const GET_RADIO_CHAN_EVAL = ADMIN_PREFIX + "/RadioChannelEvaluation";
export const RADIO_CHAN_ACTIVITY_TO_EDIT =
  ADMIN_PREFIX + "/EditActivitRadioChannel";
export const EDIT_ACTIVITY_RADIO_CHAN =
  RADIO_CHAN_ACTIVITY_TO_EDIT + "/:activityId/:radioName/:radioId/:channelId";
export const RADIO_CHAN_EVAL_TO_EDIT =
  ADMIN_PREFIX + "/EditEvaluationRadioChannel";
export const EDIT_RADIO_CHAN_EVAL =
  RADIO_CHAN_EVAL_TO_EDIT + "/:activityId/:radioName/:radioId/:channelId";
export const ADD_RADIO_CHAN_ACTIVITY =
  ADMIN_PREFIX + "/AddRadioChannelActivity";

/* Read With Jeel Component */
export const ALL_BOOK = ADMIN_PREFIX + "/GetAllBook";
// export const ADD_BOOK = ADMIN_PREFIX + "/AddBook";
// export const VIEW_BOOK = ADMIN_PREFIX + "/ViewBook";
// export const EDIT_BOOK = ADMIN_PREFIX + "/EditBook";
export const ALL_BOOK_PAGE_NAME = "GetAllBook";
/* Read With Jeel */
export const ADD_BOOK = ADMIN_PREFIX + "/AddBook";
export const BOOK_TO_EDIT = ADMIN_PREFIX + "/EditBook";
// export const EDIT_BOOK = BOOK_TO_EDIT + "/:bookId";
export const EDIT_BOOK =
  BOOK_TO_EDIT + "/:bookParentName/:bookParentId/:bookId";
export const BOOK_TO_GET = ADMIN_PREFIX + "/BookDetails";
export const VIEW_BOOK = BOOK_TO_GET + "/:bookParentName/:bookParentId/:bookId";
export const PUBLISH_BOOK = ADMIN_PREFIX + "/PublishBook";
export const GET_BOOK_EVAL = ADMIN_PREFIX + "/BookEvaluation";
export const BOOK_ACTIVITY_TO_EDIT = ADMIN_PREFIX + "/EditActivitBook";
export const EDIT_ACTIVITY_BOOK =
  BOOK_ACTIVITY_TO_EDIT + "/:activityId/:bookId";
export const BOOK_EVAL_TO_EDIT = ADMIN_PREFIX + "/EditEvaluationBook";
export const EDIT_BOOK_EVAL = BOOK_EVAL_TO_EDIT + "/:activityId/:bookId";
export const ADD_BOOK_ACTIVITY = ADMIN_PREFIX + "/AddBookActivity";

/* PDF Book */
export const ALL_PDF_BOOK = ADMIN_PREFIX + "/GetAllPdfBook";
export const ALL_PDF_BOOK_PAGE_NAME = "GetAllPdfBook";
//
export const ADD_PDF_BOOK = ADMIN_PREFIX + "/AddPdfBook";
export const PDF_BOOK_TO_EDIT = ADMIN_PREFIX + "/EditPdfBook";
//export const EDIT_PDF_BOOK = PDF_BOOK_TO_EDIT + "/:bookId";
export const EDIT_PDF_BOOK =
  PDF_BOOK_TO_EDIT + "/:bookParentName/:bookParentId/:bookId";
//export const PDF_BOOK_TO_GET = ADMIN_PREFIX + "/PdfBookDetails";
export const PDF_BOOK_TO_GET = ADMIN_PREFIX + "/PdfBookDetails";
export const VIEW_PDF_BOOK =
  PDF_BOOK_TO_GET + "/:bookParentName/:bookParentId/:bookId";
export const PUBLISH_PDF_BOOK = ADMIN_PREFIX + "/PublishPdfBook";
export const GET_PDF_BOOK_EVAL = ADMIN_PREFIX + "/PdfBookEvaluation";
export const PDF_BOOK_ACTIVITY_TO_EDIT = ADMIN_PREFIX + "/EditActivitPdfBook";
export const EDIT_ACTIVITY_PDF_BOOK =
  PDF_BOOK_ACTIVITY_TO_EDIT + "/:activityId/:bookId";
export const PDF_BOOK_EVAL_TO_EDIT = ADMIN_PREFIX + "/EditEvaluationPdfBook";
export const EDIT_PDF_BOOK_EVAL =
  PDF_BOOK_EVAL_TO_EDIT + "/:activityId/:bookId";
export const ADD_PDF_BOOK_ACTIVITY = ADMIN_PREFIX + "/AddPdfBookActivity";

/*adding blogs Routers*/
export const ALL_BLOGS = ADMIN_PREFIX + "/getAllBlogs";
export const ADD_BLOG = ADMIN_PREFIX + "/addBlog";
export const BLOG_TO_EDIT = ADMIN_PREFIX + "/editBlog";
export const EDIT_BLOG = BLOG_TO_EDIT + "/:id";

/* adding issues Routers */
export const ALL_ISSUES = ADMIN_PREFIX + "/getAllIssues";
export const GET_COMPLAINTS_ISSUES = ADMIN_PREFIX + "/getComplaintsIssues";
export const GET_SUGGESTIONS_ISSUES = ADMIN_PREFIX + "/getSuggestionsIssues";
export const ISSUE_TO_GET = ADMIN_PREFIX + "/issueDetails";
export const ISSUE_DETAIL = ISSUE_TO_GET + "/:id";

/* TermsAndConditions */
export const TERMS_AND_CONDITIONS = ADMIN_PREFIX + "/TermsAndConditions";

/* FAQ */
export const ALL_FAQ = ADMIN_PREFIX + "/FAQ";
export const ADD_FAQ = ADMIN_PREFIX + "/AddFAQ";
export const EDIT_FAQ = ADMIN_PREFIX + "/EditFAQ";

/* General Setting */
export const GENERAL_SETTING = ADMIN_PREFIX + "/generalSetting";

/* Reporting */
export const REPORT = ADMIN_PREFIX + "/report";

/* content Setting */
export const CONTENT_SETTING = ADMIN_PREFIX + "/contentSetting";
export const CONTENT_VALUES = ADMIN_PREFIX + "/ContentValues";
export const RECOMMENDATION = ADMIN_PREFIX + "/Recommendation";

/* stripe Payment  */
export const MY_STORE_CHECKOUT = "/MyStoreCheckout";

/* stripe MobilePayment   */
export const MOBILE_PAYMENT_ERROR = "/MobilePaymentError";
export const MOBILE_PAYMENT_FEEDBACK = "/MobilePaymentFeedback/:token";
export const MOBILE_PAYMENT = "/MobilePayment/:token";
export const MOBILE_PAYMENT_OFFER = "/MobilePaymentOffer";

export const MOBILE_PAYMENT_PROFILE = "/MobilePaymentProfile/:token";
export const MOBILE_PAYMENT_SUPPORT = "/MobilePaymentSupport";
export const MMOBILE_PAYMENT_HISTORY = "/MobilePaymentHistory";
export const MOBILE_PAYMENT_STRIPE_CHECKOUT =
  "/MobilePaymentStripeCheckoutParent";

export const MOBILE_PAYMENT_LOGIN = "/MobilePaymentLogin";
export const MOBILE_PAYMENT_SWITCH = "/MobilePaymentSwitch/:token";

/* fawry MobilePayment */
export const FAWRY_MOBILE_PAYMENT = "/FawryMobilePayment/:token";
export const FAWRY_FAILURE = "/FawryFailure";
export const FAWRY_SUCCESS = "/FawrySuccess/data";
export const FAWRY_RESULT = "/FawryResult";

/* PaymentPackages */
export const PAYMENT_PACKAGES = ADMIN_PREFIX + "/PaymentPackages";
/* PaymentPackages */
export const PAYMENT_COUNTRIES = ADMIN_PREFIX + "/PaymentCountries";

/* PromoCodes*/
export const PROMO_CODES = ADMIN_PREFIX + "/PromoCodes";
export const GET_CODE_TO_VIEW = ADMIN_PREFIX + "/ViewCode";
export const VIEW_PROMO_CODE = GET_CODE_TO_VIEW + "/:codeUid";
export const ADD_PROMO_CODE = ADMIN_PREFIX + "/AddPromoCode";
export const GET_CODE_TO_EDIT = ADMIN_PREFIX + "/EditCode";
export const EDIT_PROMO_CODE = GET_CODE_TO_EDIT + "/:codeUid";
export const GET_CODE_TO_VIEW_PUBLIC = "/ViewCodePublic/:codeName";

/* QRcodesGenerator */
export const QR_CODE_GENERATOR = ADMIN_PREFIX + "/QRcodesGenerator";
export const QR_CODE_CARD_UPLOAD = ADMIN_PREFIX + "/QRcodeCardUpload";

/* Vendor */
export const VENDORS = ADMIN_PREFIX + "/Vendor";
export const ADD_VENDOR = ADMIN_PREFIX + "/AddVendor";
export const GET_VENDOR_TO_EDIT = ADMIN_PREFIX + "/EditVendor";
export const EDIT_VENDOR = GET_VENDOR_TO_EDIT + "/:id";
/* Group */
export const GROUPS = ADMIN_PREFIX + "/Group";
export const ADD_GROUP = ADMIN_PREFIX + "/AddGroup";
export const GET_GROUP_TO_EDIT = ADMIN_PREFIX + "/EditGroup";
export const EDIT_GROUP = GET_GROUP_TO_EDIT + "/:id";
export const GET_MEMBERS_TO_VIEW = ADMIN_PREFIX + "/ViewGroup";
export const VIEW_GROUP_MEMBERS = GET_MEMBERS_TO_VIEW + "/:id";

/*IN App Notification*/
export const ADD_IN_APP_NOTIFICATION = ADMIN_PREFIX + "/AddInAppNotification";
export const GET_IN_APP_NOTIFICATION_TO_EDIT =
  ADMIN_PREFIX + "/EditInAppNotification";
export const EDIT_IN_APP_NOTIFICATION =
  GET_IN_APP_NOTIFICATION_TO_EDIT + "/:id";
export const IN_APP_NOTIFICATION = ADMIN_PREFIX + "/InAppNotification";

/* Dynamic Sections */
export const SECTIONS = ADMIN_PREFIX + "/Section";
export const ADD_SECTION = ADMIN_PREFIX + "/AddSection";
export const GET_SECTION_TO_EDIT = ADMIN_PREFIX + "/EditSection";
export const EDIT_SECTION = GET_SECTION_TO_EDIT + "/:id";

/* VendorReport */
export const VENDOR_REPORT = ADMIN_PREFIX + "/VendorReport";

/* Portal Paths */
export const PORTAL_LAND_PAGE = "/";
export const PORTAL_SIGN_IN = "/login";
export const PORTAL_REGISTER = "/register";
export const PORTAL_LAND_PAGE_SUBSCRIBE_NOW = "/#subscribe-now";
export const PORTAL_LOGIN_METHODS = "/otherLoginMethods";
export const PORTAL_LOGGED_BY_PHONE = "/loggedInByPhone";
export const PORTAL_PHONE_CODE = "/phoneCode";
export const PORTAL_FORGET_PASSWORD = "/forgetPassword";
export const PORTAL_ACCOUNT = "/account";
export const PORTAL_ACCOUNT_PROFILE = "/accountProfile";
export const PORTAL_SUBSCRIPTION_HISTORY = "/paymentSubscriptionHistory";
export const PORTAL_SUBSCRIPTION = "/subscription";
export const PORTAL_UPGRADE_SUBSCRIPTION = "/upgrade-subscription";
export const PORTAL_PAYMENT_METHODS = "/paymentMethods";
export const PORTAL_CREDIT_CARD = "/creditCard";
export const PORTAL_FAWRY = "/fawry";
export const PORTAL_PRE_PAID = "/prePaidCard";
export const PORTAL_CHANGE_LOGIN_METHODS = "/changeLoginMethod";
export const PORTAL_PAYMENT_RESULT = "/paymentResult";
export const PORTAL_COMPLETE_PROCESS = "/complete-process";
export const PORTAL_MADA = "/mada";
export const PORTAL_SUCCESSFUL = "/successful";

export const FIT_LOGIN = "/FitLogin/:type/:fitId";
export const FIT_V_CODE = "/FitVCode/:telecomCompany/:phone";
export const FIT_SUCCESS = "/FitSuccess/:type/:fitId/:phone";

//export const MW_V_CODE = "/MWVCode/:telecomCompany/:phone";
//export const MW_SUCCESS = "/MWSuccess/:phone";
export const Mw_ZainIraq_V_CODE = "/MwZainIraqVCode/:telecomCompany/:phone";
export const Mw_ZainIraq_SUCCESS = "/MwZainIraqSuccess/:telecomCompany/:phone";

export const Mw_Bahrain_V_CODE = "/MwBahrainVCode/:telecomCompany/:phone";
export const Mw_Bahrain_SUCCESS = "/MwBahrainSuccess/:telecomCompany/:phone";

export const Mw_Qatar_V_CODE = "/MwQatarVCode/:telecomCompany/:phone";
export const Mw_Qatar_SUCCESS = "/MwQatarSuccess/:telecomCompany/:phone";

export const MONDIA_SUCCESS = "/mondiaSuccess/:phone";

export const TPAY_LOGIN = "/TpayLogin";
export const TPAY_PHONE_CODE =
  "/TpayPhoneCode/:phone/:tpaySubContractId/:tpayTransactionId/:telecomCompany";
export const TPAY_SUCCESS = "/TpaySuccess/:phone";

export const TELECOMUNICATION_LOGIN = "/telecomLogin";
export const MONDIA_LOGIN = "/mondiaLogin";
export const TELECOM_SUCCESS = "/telecomSuccess";
export const TELECOMUNICATION_SUCCESS =
  TELECOM_SUCCESS + "/:type" + "/:service" + "/:phone";

//{`${ADMIN_PREFIX}` + "/login"}

export const HYPERPAY = "/HyperPay";
export const RESULTPAGE = "/ResultPage/:id";
export const Result = "/result";

/*adding banners Routers*/
// export const BANNERS = ADMIN_PREFIX + "/banners";
export const ALL_BANNERS = ADMIN_PREFIX + "/getAllBanners";
export const ADD_BANNER = ADMIN_PREFIX + "/addBanner";
export const BANNER_TO_EDIT = ADMIN_PREFIX + "/updateBanner";
export const EDIT_BANNER = BANNER_TO_EDIT + "/:id";

export const SETTINGS = ADMIN_PREFIX + "/getSettings";
export const ADD_SETTING = ADMIN_PREFIX + "/addSettings";
export const EDIT_SETTING = ADMIN_PREFIX + "/updateSettings";
export const GET_SETTING_TO_EDIT = EDIT_SETTING + "/:id";

/*adding Educational Videos Routers*/
export const ALL_EDUCATIONAL_VIDEOS = ADMIN_PREFIX + "/getAllParentLibrary";
export const ADD_EDUCATIONAL_VIDEO = ADMIN_PREFIX + "/addParentLibrary";
export const EDUCATIONAL_VIDEO_TO_EDIT = ADMIN_PREFIX + "/updateParentLibrary";
export const EDUCATIONAL_VIDEO_TO_VIEW = ADMIN_PREFIX + "/viewParentLibrary";
export const EDIT_EDUCATIONAL_VIDEO = EDUCATIONAL_VIDEO_TO_EDIT + "/:id";
export const VIEW_EDUCATIONAL_VIDEO = EDUCATIONAL_VIDEO_TO_VIEW + "/:id";

/* Content Vendors */
export const CONTENT_VENDORS = ADMIN_PREFIX + "/ContentVendor";
export const CONTENT_ADD_VENDOR = ADMIN_PREFIX + "/AddContentVendor";
export const CONTENT_GET_VENDOR_TO_EDIT = ADMIN_PREFIX + "/EditContentVendor";
export const CONTENT_EDIT_VENDOR = CONTENT_GET_VENDOR_TO_EDIT + "/:id";

/* Profit Management */
export const PROFIT_SHARE_New = ADMIN_PREFIX + "/ProfitShareNew";

/* Partener Management */
export const Partener_Cards = PARTENER_PREFIX + "/cards";
export const Partener_Vouchers = PARTENER_PREFIX + "/voucher";
export const Partener_Batch_Assign = PARTENER_PREFIX + "/batch/assign";

/* */
export const CONTENT_VENDOR_GET_VIEWS_REPORT =
  ADMIN_PREFIX + "/contentVendorViewsReport";
